import React from 'react';
import { PageProps } from 'gatsby';

import Layout from '../components/Layout';

import HomeContent from '../contents/home';

const HomePage: React.FC<PageProps> = (props) => {
  return (
    <Layout location={props.location}>
      <HomeContent />
    </Layout>
  );
};

export default HomePage;
